import React, { useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import find from 'lodash/find';
import head from 'lodash/head';
import PropTypes from 'prop-types';

import Typography from 'components/uiLibrary/Typography';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton

import queries from 'containers/Globals/queries';
import { TP, ENTITY_TYPE, ENTITY_MAIN_TABS } from 'constants/index';

import { useTranslation, i18n } from 'src/i18n';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import usePageContext from 'utils/hooks/usePageContext';

import classes from './EntityBioSummary.module.scss';
import SectionBlock from '../SectionBlock';

const trackingData = {
  section: SECTIONS.BIOGRAPHY,
};

const EntityBioSummary = ({ entity, entityType, sectionProps = {} }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();

  const { data: bio, isLoading } = useQuery(
    queries.getEntityBioDetails({
      entityType,
      entityId: entity?.id,
      queryConfig: { enabled: entity?.stats?.about?.exists && entityType === ENTITY_TYPE.ARTIST },
    }),
  );

  const bioInfo = useMemo(() => {
    if (isLoading) {
      return {};
    }

    const individualLang = typeof window === 'undefined' ? bio?.lang : i18n.language;
    const entityBio =
      find(bio?.data, { language: { lang: individualLang } }) ||
      find(bio?.data, { language: { lang: 'en' } }) ||
      head(bio?.data);

    return {
      subTitle: `${TP}.m_BIO`,
      linkProps: navigate.getLinkProps({
        entity,
        entityType,
        path: ENTITY_MAIN_TABS.BIO,
      }),
      content: entityBio?.excerpt,
      lang: individualLang,
      languageNames: bio?.data?.map(lang => lang?.language?.name)?.join(', '),
    };
  }, [isLoading, entityType, navigate, entity, bio?.lang, bio?.data]);

  const { subTitle, linkProps, content, lang, languageNames } = bioInfo;

  if (isLoading) {
    return <Skeleton variant="rectangular" height={50} />;
  }

  if (!content) {
    return null;
  }

  return (
    <SectionBlock
      title={t(subTitle)}
      linkProps={linkProps}
      className={classes.bio}
      {...sectionProps}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
    >
      <Link
        {...linkProps}
        disableUnderline
        disableHover
        className={classes.linkWrapper}
        scroll
        trackingData={trackingData}
      >
        <div className={classes.content} lang={lang}>
          <Typography variant="p" className={classes.excerpt} color="inherit" size={13}>
            {content}
          </Typography>
          <Typography variant="span" size={12} color="none" className={classes.readMoreLink}>
            ...{t(`${TP}.FN_READ_MORE`)}
          </Typography>
        </div>
        <Typography size={12} color="none" italic className={classes.bioLanguages}>
          {t(`${TP}.FN_AVAILABLE_IN`)}: {languageNames}
        </Typography>
      </Link>
    </SectionBlock>
  );
};

EntityBioSummary.propTypes = {
  entity: PropTypes.object,
  entityType: PropTypes.string,
};

export default EntityBioSummary;
