import React, { useMemo, useState } from 'react';

import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import ReviewModal from 'components/Reviews/Display/ReviewModal';
import ReviewCard from 'components/Reviews/Display/ReviewCard';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import SectionBlock from 'components/Globals/SectionBlock';

import queries from 'containers/Reviews/queries';

import { useQuery } from 'utils/react-query';
import usePageContext from 'utils/hooks/usePageContext';
import { ACCESS_TYPE, ENTITY_DISPLAY_STATUS, ENTITY_MAIN_TABS, ENTITY_TYPE, TP } from 'constants/index';
import { useTranslation } from 'src/i18n';
import { COMPONENTS, SECTIONS } from '../Analytics/constants';
import useTracking from '../Analytics';
import EntityViewAllCard from '../EntityViewAllCard';

import classes from './EntityFeaturedReview.module.scss';

const EntityFeaturedReview = ({ entityType, entity, hideTitle = false }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const { navigate } = usePageContext();
  const [modalReview, setModalReview] = useState(null);
  const hasPremiumSubscription = entity?.stats?.isPro;
  const trackingData = {
    section: SECTIONS.REVIEW,
    component: COMPONENTS.FEATURED_REVIEW,
  };

  const { data, isFetching } = useQuery(
    queries.getFeaturedReviews({
      entityType,
      entityId: entity?.id,
      queryConfig: {
        enabled: !!entity?.id && entity?.stats?.reviews?.featured?.exists,
        select: response => ({
          total: response?.total,
          data: response?.data?.filter(review => review?.highlight),
        }),
      },
    }),
  );

  const linkProps = useMemo(
    () =>
      navigate.getLinkProps({
        entity,
        entityType,
        path: ENTITY_MAIN_TABS.REVIEWS,
        onlyLinkProps: true,
      }),
    [entity, entityType, navigate],
  );

  const featuredReviews = useMemo(() => {
    if (!data?.total) {
      return [];
    }

    const entityKey = entityType === ENTITY_TYPE?.ARTIST ? ACCESS_TYPE.PROFILE : ACCESS_TYPE.ORGANIZATION;

    return data?.data?.filter(review =>
      review?.tags?.find(tag => {
        if (tag?.[entityKey]?.id === entity?.id) {
          if (hasPremiumSubscription) {
            return tag?.displayStatus === ENTITY_DISPLAY_STATUS.SHOW;
          }
          return true;
        }
        return false;
      }),
    );
  }, [data, hasPremiumSubscription, entity, entityType]);

  const onOpenReviewModal = review => {
    setModalReview(review);
    track.click({
      ...trackingData,
      component: COMPONENTS.REVIEW_MODAL,
    });
  };

  if (isFetching && !featuredReviews?.length) {
    return <Skeleton variant="rectangular" height={40} />;
  }

  if (!featuredReviews?.length) {
    return null;
  }

  return (
    <SectionBlock title={t(`${TP}.FN_TAB_REVIEWS`)} withTitle={!hideTitle}>
      <HorizontalScroller outset>
        <div className={classes.scrollableContent}>
          {featuredReviews.map((review, index) => (
            <ReviewCard
              key={index}
              review={review}
              withProductionInfo={false}
              onClick={rev => onOpenReviewModal(rev)}
              trackingData={trackingData}
            />
          ))}

          {entity?.stats?.reviews?.featured?.total > featuredReviews?.length && (
            <EntityViewAllCard
              title={t(`${TP}.FN_PROFILE_REVIEWS_SECTION_TITLE`)}
              total={entity.stats.reviews.featured.total}
              linkProps={linkProps}
              styles={{ root: classes.viewAllCard }}
            />
          )}
        </div>
      </HorizontalScroller>

      {modalReview && <ReviewModal content={modalReview} onCloseModal={() => setModalReview(false)} />}
    </SectionBlock>
  );
};

export default EntityFeaturedReview;
